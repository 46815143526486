.wrapper {
  --s-wrapper-bg-color: #f8f8f8;
  --s-searchresult-wrapper-bg-color: #fff;
  --s-searchresult-wrapper-boxshadow: #00000026;
  --s-searchresult-item-focus: #f2f2f2;
  --s-searchresult-item-link-color: #1c1c1c;
  --s-stroke: #1c1c1c;
  --s-input-color: #1c1c1c;
  --s-sec-color: #898989;

  &.dark {
    --s-wrapper-bg-color: #2e2e2e;
    --s-searchresult-wrapper-bg-color: #2e2e2e;
    --s-searchresult-wrapper-boxshadow: rgb(0 0 0 / 15%);
    --s-searchresult-item-focus: #5b5b5b;
    --s-searchresult-item-link-color: #fff;
    --s-stroke: #fff;
    --s-input-color: #fff;
    --s-sec-color: #898989;
  }

  position: relative;
  min-width: rem(300);
  background: transparent !important;
  border-radius: 4px;
  border: 1px solid #7773;
}

.list_ResultSearch {
  padding: 0.9rem 1.2rem !important;
  display: flex;
  align-items: center;
  gap: 0.9rem;
}


.searchInput_wrapper {
  display: flex;
  align-items: center;
  // border: 1px solid transparent;
  padding: rem(12);
  border-radius: 2px;
  justify-content: flex-end;

  .input {
    width: 100%;
    color: #fff;
    background: transparent !important;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
  }

  .searchIcon {
    opacity: 0.7;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;

    svg path {
      stroke: var(--s-stroke);
    }

    &:hover {
      opacity: 1;
    }
  }
}

.searchResult_wrapper {
  position: absolute;
  width: 100%;
  min-width: rem(320);
  background-color: var(--s-searchresult-wrapper-bg-color);
  box-shadow: 35px 15px 120px 0 var(--s-searchresult-wrapper-boxshadow);
  margin-top: rem(12);
  max-height: rem(700);
  overflow: scroll !important;
  right: 0;
  max-height: 600px;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    left: 0;
  }

  a:hover {
    background-color: #f6911917;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
  }

  .searchResult_item {
    padding: 10px 16px !important;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;

    &_link {
      cursor: pointer;
      color: var(--s-searchresult-item-link-color);

      &:hover,
      &:focus {
        background-color: #f6911917;
      }
    }

    &_loading {
      padding: rem(60);
    }

    &_member {
      flex-direction: row;
      gap: rem(8);
    }
  }

  .searchResult_collectionName,
  .searchResult_creatorName {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    flex: 1;
  }

  .searchResult_creatorName {
    font-size: 12px;
    color: #9da2b1;
  }

  .ant-typography {
    color: #898989 !important;
    font-size: 14px;
  }

  .list_heading {
    padding-top: 10px;
    padding-left: 10px;
  }

  .searchResult_collectionThumbnail {
    width: rem(34px);
    height: rem(34px);
    background-color: #f7f9fb;

    img {
      object-fit: contain;
      width: 38px;
      height: 100%;
    }
  }
}